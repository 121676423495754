import { Table } from '@devstart/react-bootstrap';
import { Link, navigate } from 'gatsby';
import { find, first } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { createSelector } from 'reselect';

import { regeneratePathAndHistory } from '../../../../utils/polyvinyl';
import TrophyChallengeFilled from '../../assets/icons/trophy-challenge-filled';
import { projectMap } from '../../resources/cert-and-project-map';
import ProjectPreviewModal from '../../templates/Challenges/components/project-preview-modal';
import { openModal } from '../../templates/Challenges/redux/actions';
import { FlashMessages } from '../Flash/redux/flash-messages';
import ProjectModal from '../SolutionViewer/project-modal';
import { Spacer } from '../helpers';
import { SolutionDisplayWidget } from '../solution-display-widget';
import CustomButton from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import SectionHeader from './section-header';

import './certification.css';

configureAnchors({ offset: -40, scrollDuration: 0 });

const propTypes = {
  completedChallenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      solution: PropTypes.string,
      githubLink: PropTypes.string,
      challengeType: PropTypes.number,
      completedDate: PropTypes.number,
      challengeFiles: PropTypes.array
    })
  ),
  createFlashMessage: PropTypes.func.isRequired,
  isHonest: PropTypes.bool,
  openModal: PropTypes.func,
  t: PropTypes.func.isRequired,
  username: PropTypes.string,
  verifyCert: PropTypes.func.isRequired,
  isLogicaDeProgramacaoCert: PropTypes.bool,
  isHtmlCssJavascriptCert: PropTypes.bool,
  isReactCert: PropTypes.bool
};

const mapDispatchToProps = {
  openModal
};

const certifications = Object.keys(projectMap);
const isCertSelector = ({
  isLogicaDeProgramacaoCert,
  isHtmlCssJavascriptCert,
  isReactCert
}) => ({
  isLogicaDeProgramacaoCert,
  isHtmlCssJavascriptCert,
  isReactCert
});

const isCertMapSelector = createSelector(
  isCertSelector,
  ({ isLogicaDeProgramacaoCert, isHtmlCssJavascriptCert, isReactCert }) => ({
    'Lógica de Programação': isLogicaDeProgramacaoCert,
    'HTML, CSS e Javascript': isHtmlCssJavascriptCert,
    React: isReactCert
  })
);

const honestyInfoMessage = {
  type: 'info',
  message: FlashMessages.HonestFirst
};

const initialState = {
  solutionViewer: {
    projectTitle: '',
    challengeFiles: null,
    solution: null,
    isOpen: false
  }
};

export class CertificationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  handleSolutionModalHide = () => this.setState({ ...initialState });

  getUserIsCertMap = () => isCertMapSelector(this.props);

  isSolved = projectId => {
    const { completedChallenges } = this.props;
    const completedProject = find(
      completedChallenges,
      ({ id }) => projectId === id
    );
    return !!completedProject;
  };

  getProjectSolution = (projectId, projectTitle, link) => {
    const { completedChallenges, openModal } = this.props;
    const completedProject = find(
      completedChallenges,
      ({ id }) => projectId === id
    );

    if (!completedProject) {
      return (
        <CustomButton buttonType={ButtonTypes.Primary} width='100%' href={link}>
          Iniciar
        </CustomButton>
      );
    }

    const { solution, challengeFiles } = completedProject;
    const showUserCode = () =>
      this.setState({
        solutionViewer: {
          projectTitle,
          challengeFiles,
          solution,
          isOpen: true
        }
      });

    const challengeData = completedProject
      ? {
          ...completedProject,
          challengeFiles:
            completedProject?.challengeFiles?.map(regeneratePathAndHistory) ??
            null
        }
      : null;

    const showProjectPreview = () => {
      this.setState({
        projectViewer: {
          previewTitle: projectTitle,
          challengeData
        }
      });
      openModal('projectPreview');
    };

    return (
      <SolutionDisplayWidget
        completedChallenge={completedProject}
        dataCy={projectTitle}
        projectTitle={projectTitle}
        showUserCode={showUserCode}
        showProjectPreview={showProjectPreview}
        displayContext='settings'
      ></SolutionDisplayWidget>
    );
  };

  renderCertifications = (certName, projectsMap) => {
    const { t } = this.props;
    const { certSlug } = first(projectsMap[certName]);
    return (
      <div className='certification-container' key={certName}>
        <Spacer size='small' />
        <h3 className='text-center' id={`cert-${certSlug}`}>
          {t(`certification.title.${certName}`, certName)}
        </h3>
        <Table>
          <tbody>
            {this.renderProjectsFor(
              certName,
              this.getUserIsCertMap()[certName],
              projectsMap
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  renderProjectsFor = (certName, isCert, projectsMap) => {
    const { username, isHonest, createFlashMessage, t, verifyCert } =
      this.props;
    const { certSlug } = first(projectsMap[certName]);
    const certLocation = `/certification/${username}/${certSlug}`;
    const createClickHandler = certSlug => e => {
      e.preventDefault();
      if (isCert) {
        return navigate(certLocation);
      }
      return isHonest
        ? verifyCert(certSlug)
        : createFlashMessage(honestyInfoMessage);
    };

    const hasFinishedAllProjects = projectsMap[certName].every(({ id }) => {
      return this.isSolved(id);
    });

    return (
      <>
        {projectsMap[certName].map(({ link, title, id }) => (
          <tr className='project-row' key={id}>
            <td className='project-title col-sm-9'>
              {this.isSolved(id) ? (
                <Link to={link}>
                  {t(`certification.project.title.${title}`, title)}
                </Link>
              ) : (
                <p>{title}</p>
              )}
            </td>
            <td className='project-solution col-sm-3'>
              {this.getProjectSolution(id, title, link)}
            </td>
          </tr>
        ))}

        {hasFinishedAllProjects && (
          <tr key={`cert-${certSlug}-button`}>
            <td colSpan={2}>
              <CustomButton
                data-cy={`btn-for-${certSlug}`}
                onClick={createClickHandler(certSlug)}
                buttonType={
                  isCert ? ButtonTypes.Primary : ButtonTypes.Secondary
                }
                width='100%'
              >
                <TrophyChallengeFilled
                  className='icon-settings'
                  fill={isCert ? 'black' : '#F081B8'}
                />
                {isCert ? t('buttons.show-cert') : t('buttons.claim-cert')}{' '}
                <span className='sr-only'>{certName}</span>
              </CustomButton>
            </td>
          </tr>
        )}
      </>
    );
  };

  render() {
    const { solutionViewer, projectViewer } = this.state;
    const { t } = this.props;

    return (
      <ScrollableAnchor id='certification-settings'>
        <section className='certification-settings'>
          <SectionHeader>{t('settings.headings.certs')}</SectionHeader>
          <hr />
          {certifications.map(certName =>
            this.renderCertifications(certName, projectMap)
          )}
          <ProjectModal
            {...solutionViewer}
            handleSolutionModalHide={this.handleSolutionModalHide}
          />
          <ProjectPreviewModal
            {...projectViewer}
            closeText={t('buttons.close')}
            showProjectPreview={true}
          />
        </section>
      </ScrollableAnchor>
    );
  }
}

CertificationSettings.displayName = 'CertificationSettings';
CertificationSettings.propTypes = propTypes;

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(CertificationSettings));
