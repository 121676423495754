import { Grid, Row } from '@devstart/react-bootstrap';
import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import envData from '../../../config/env.json';
import {
  createFlashMessage,
  removeFlashMessage
} from '../components/Flash/redux';
import { Loader, Spacer } from '../components/helpers';
import About from '../components/settings/about';
import Certification from '../components/settings/certification';
import DangerZone from '../components/settings/danger-zone';
import Email from '../components/settings/email';
import Honesty from '../components/settings/honesty';
import { Themes, type ThemeProps } from '../components/settings/theme';
import UserToken from '../components/settings/user-token';
import { hardGoTo as navigate } from '../redux/actions';
import { User } from '../redux/prop-types';
import {
  isSignedInSelector,
  signInLoadingSelector,
  userSelector,
  userTokenSelector
} from '../redux/selectors';
import {
  submitNewAbout,
  updateMyHonesty,
  updateMyKeyboardShortcuts,
  updateMyPortfolio,
  updateMyQuincyEmail,
  updateMySocials,
  updateMySound,
  updateMyTheme,
  verifyCert
} from '../redux/settings/actions';
import './show-settings.css';
import { AccessLevel } from '../utils/enums/access-levels';

const { apiLocation } = envData;

// TODO: update types for actions
type ShowSettingsProps = Pick<ThemeProps, 'toggleNightMode'> & {
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
  isSignedIn: boolean;
  navigate: (location: string) => void;
  showLoading: boolean;
  submitNewAbout: () => void;
  toggleSoundMode: (sound: boolean) => void;
  toggleKeyboardShortcuts: (keyboardShortcuts: boolean) => void;
  updateSocials: (formValues: Socials) => void;
  updateIsHonest: () => void;
  updatePortfolio: () => void;
  updateQuincyEmail: (isSendQuincyEmail: boolean) => void;
  user: User;
  verifyCert: () => void;
  path?: string;
  userToken: string | null;
};

const mapStateToProps = createSelector(
  signInLoadingSelector,
  userSelector,
  isSignedInSelector,
  userTokenSelector,
  (showLoading: boolean, user: User, isSignedIn, userToken: string | null) => ({
    showLoading,
    user,
    isSignedIn,
    userToken
  })
);

const mapDispatchToProps = {
  createFlashMessage,
  removeFlashMessage,
  navigate,
  submitNewAbout,
  toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
  toggleSoundMode: (sound: boolean) => updateMySound({ sound }),
  toggleKeyboardShortcuts: (keyboardShortcuts: boolean) =>
    updateMyKeyboardShortcuts({ keyboardShortcuts }),
  updateSocials: (formValues: Socials) => updateMySocials(formValues),
  updateIsHonest: updateMyHonesty,
  updatePortfolio: updateMyPortfolio,
  updateQuincyEmail: (sendQuincyEmail: boolean) =>
    updateMyQuincyEmail({ sendQuincyEmail }),
  verifyCert
};

export function ShowSettings(props: ShowSettingsProps): JSX.Element {
  const { t } = useTranslation();
  const {
    createFlashMessage,
    removeFlashMessage,
    isSignedIn,
    submitNewAbout,
    toggleNightMode,
    toggleSoundMode,
    toggleKeyboardShortcuts,
    user: {
      access,
      completedChallenges,
      email,
      isLogicaDeProgramacaoCert,
      isHtmlCssJavascriptCert,
      isReactCert,
      isEmailVerified,
      isHonest,
      username,
      about,
      picture,
      points,
      theme,
      sound,
      keyboardShortcuts,
      location,
      name,
      cpf,
      phone
      // githubProfile,
      // linkedin,
      // twitter,
      // website,
      // portfolio
    },
    navigate,
    showLoading,
    //updateQuincyEmail,
    // updateSocials,
    // updatePortfolio,
    updateIsHonest,
    verifyCert,
    userToken
  } = props;
  const isSignedInRef = useRef(isSignedIn);

  if (showLoading) {
    return <Loader fullScreen={true} />;
  }

  if (!isSignedInRef.current) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  return (
    <>
      <Helmet title={`${t('buttons.settings')} | DEVstart`} />
      <Grid className='settings-wrapper'>
        <Row>
          <Spacer size='large' />
          <main className='container-settings'>
            <Spacer size='small' />
            <h1
              className='text-center title-section'
              style={{ overflowWrap: 'break-word' }}
            >
              {t('settings.title')}
            </h1>
            <About
              about={about}
              currentTheme={theme}
              location={location}
              name={name}
              picture={picture}
              points={points}
              sound={sound}
              cpf={cpf}
              phone={phone}
              keyboardShortcuts={keyboardShortcuts}
              submitNewAbout={submitNewAbout}
              toggleNightMode={toggleNightMode}
              toggleSoundMode={toggleSoundMode}
              toggleKeyboardShortcuts={toggleKeyboardShortcuts}
              username={username}
              createFlashMessage={createFlashMessage}
              removeFlashMessage={removeFlashMessage}
            />
            {/* <Spacer size='medium' />
            <Internet
              githubProfile={githubProfile}
              linkedin={linkedin}
              twitter={twitter}
              updateSocials={updateSocials}
              website={website}
            /> */}
            {/* <Spacer size='medium' />
            <Portfolio
              portfolio={portfolio}
              updatePortfolio={updatePortfolio}
            /> */}
            <Email email={email} isEmailVerified={isEmailVerified} />
            <Spacer size='medium' />
            <Honesty isHonest={isHonest} updateIsHonest={updateIsHonest} />
            <Spacer size='medium' />
            {access !== AccessLevel.LimitedNacional &&
              access !== AccessLevel.CompleteNacional && (
                <Certification
                  completedChallenges={completedChallenges}
                  createFlashMessage={createFlashMessage}
                  isLogicaDeProgramacaoCert={isLogicaDeProgramacaoCert}
                  isHtmlCssJavascriptCert={isHtmlCssJavascriptCert}
                  isReactCert={isReactCert}
                  isHonest={isHonest}
                  username={username}
                  verifyCert={verifyCert}
                />
              )}
            {userToken && (
              <>
                <Spacer size='medium' />
                <UserToken />
              </>
            )}
          </main>

          <main className='container-settings'>
            <DangerZone />
          </main>
        </Row>
      </Grid>
      <Spacer size='medium' />
    </>
  );
}

ShowSettings.displayName = 'ShowSettings';

export default connect(mapStateToProps, mapDispatchToProps)(ShowSettings);
