import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../ui/Modal';
import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';

import './reset-modal.css';

type ResetModalProps = {
  readonly onHide: () => void;
  readonly reset: () => void;
  readonly show: boolean;
};

function ResetModal(props: ResetModalProps): JSX.Element {
  const { show, onHide } = props;

  const { t } = useTranslation();

  return (
    <Modal.Root backdrop={true} keyboard={true} onHide={onHide} show={show}>
      <Modal.Header closeButton>
        {t('settings.danger.reset-heading')}
      </Modal.Header>
      <Modal.Body>
        <p>{t('settings.danger.reset-p1')}</p>
        <p>
          <strong>{t('settings.danger.reset-p2')}</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          type='button'
          buttonType={ButtonTypes.Light}
        >
          {t('settings.danger.nevermind-2')}
        </Button>
        <Button
          onClick={props.reset}
          type='button'
          buttonType={ButtonTypes.Danger}
        >
          {t('settings.danger.reset-confirm')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

ResetModal.displayName = 'ResetModal';

export default ResetModal;
